.imglistli {
    width: 25vw;
    aspect-ratio: 1;
    overflow: hidden;
}


.imglistimg {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
}
.imginfocontainer {
    width: 100%;
    height: 0;
    line-height: 0;
    overflow: visible;
}
.imginfo {
    height: 4em;
    width: 100%;
    transform: translateY(0%);
    transition: all 0.2s ease;
    font-family: "Podkova", serif;
    font-size: 24px;
    line-height: 28px;
    color: white;
    display: flex;
    align-items: flex-end;
    text-align: center;
}

.imginfo .bottom {
    padding: 0.5em;
    display: block;
    height: 100%;
    width: 100%;
    text-align: center;
    background-color: #2e2e2ecf;
}

.imglistli:hover .imginfo{
    transform: translateY(-100%);
}

.imglistconstrain {
    width: 100vw;
    display: flex;
    overflow: scroll;
    position: relative;
    line-height: 0;
}


.imglistcontainer {
    display: flex;
    list-style: none;
    margin-block-start: unset;
    margin-block-end: unset;
    margin-inline-start: unset;
    margin-inline-end: unset;
    padding-inline-start: unset;
    animation: f 45s linear;
    animation-iteration-count: infinite;
}

#projects {
    padding: 40px 0;
}

@keyframes f {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

.forward {
    /* transform: translateX(50vw); */
}
.backward {
    /* transform: translateX(-50vw); */
}

.forward > .imglistfakep{
    animation-direction: normal
}

.forward > .imglistfakem{
    animation-direction: normal
}
.forward > .imglistfakel{
    animation-direction: normal
}

.backward > .imglistfakep {
    animation-direction: reverse
}
.backward > .imglistfakem {
    animation-direction: reverse
}
.backward > .imglistfakel {
    animation-direction: reverse
}