.App {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  background-color: #00000066;
}

.projects-split {
  display: block;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.menu {
  position: fixed;
  display: flex;
  top: 0;
  flex-direction: row;
  align-items: center;
  gap: 50px;
  z-index: 20;
  background-color: #00000077;
  width: 100%;
}

.menu-tab {
  font-family: "Abhaya Libre", sans-serif;
  font-size: 26px;
  font-weight: 600;
  color: white;
  cursor: pointer;
}



.tab-active {
  color: #F25925;
}

.menu-tab:hover {
  color: #F25925;
}

.menu img {
  width: 128px;
  height: 128px;
}

.grayfilter {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;

  background-color: #00000088;
}

.home {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.home-main {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  margin-top: 180px;
  width: 100%;
  z-index: 10;
  margin-right: 10%;
}

.home-main div {
  color: white;
  font-family: "Oswald", sans-serif;
}

.main-title {
  font-size: 56px;
}

.main-desc {
  margin-top: 10px;
  font-size: 24px;
  font-weight: 400;
}

.learn-btn {
  border: 2px solid #F25925;
  border-radius: 999px;
  padding: 15px 50px;
  font-size: 24px;
  margin-top: 40px;
  cursor: pointer;
}

.learn-btn:hover {
  background-color: #F25925;
}

.about {
  display: flex;
  flex-direction: row;
  padding: 40px 80px;
  gap: 40px;
}

.about-left {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.about-title {
  color: white;
  font-size: 48px;
  font-family: "Podkova", serif;
  font-weight: 400;
}

.about-desc {
  color: black;
  font-size: 22px;
  font-family: "Podkova", serif;
  font-weight: 400;
  margin-top: 20px;
}

.about-right {
  width: 50%;
  position: relative;
  overflow: visible;
}

.about-bg-desc {
  position: absolute;
  right: 40px;
  bottom: -40px;
  /* top: 0px; */
  background-color: #F1EDED;
  font-family: "Cutive Mono", monospace;
  font-weight: 400;
  padding: 20px 40px;
  text-align: center;
}

.services {
  padding: 40px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
}

.service-title {
  font-family: "Oswald", sans-serif;
  font-size: 60px;
  z-index: 5;
}

.service-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  /* align-items: center; */
  width: 20%;
  padding: 30px 20px;
  border-radius: 20px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2), 0px 6px 20px rgba(0, 0, 0, 0.19);
}

.service-name {
  font-family: "Praise", cursive;
  font-size: 60px;
}

.service-desc {
  font-size: 24px;
  font-family: "Podkova", serif;
  text-align: center;
}

.services-bg {
  position: absolute;
  background-color: white;
  width: 100%;
  height: calc(100% - 260px);
  top: 260px;
  left: 0;
}

.flex-3 {
  z-index: 5;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 40px;
  width: 100%;
  /* Optional: Adds space between grid items */
}

.fake-btn {
  background-color: #F25925;
  border-radius: 999px;
  padding: 10px 30px;
  z-index: 5;
  font-family: "Podkova", serif;
  font-size: 22px;
  font-weight: bold;
}

.team {
  width: 100%;
  position: relative;
  padding: 40px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.magic-panel {
  position: relative;
  height: 800px;
  width: 1000px;
}

.member {
  border: 4px solid white;
  border-radius: 999px;
  cursor: pointer;
  position: absolute;
}

.member img {
  border-radius: 999px;
  object-fit: cover;
}

.member1 {
  width: 300px;
  height: 300px;
}

.member2 {
  width: 340px;
  height: 340px;
  left: 530px;
}

.member3 {
  width: 400px;
  height: 400px;
  left: 200px;
  top: 180px;
}

.member4 {
  width: 250px;
  height: 250px;
  top: 520px;
  left: 100px;
}

.member-panel {
  width: 500px;
  height: fit-content;
  background-color: #F25925;
  padding: 20px 40px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: start;
  position: absolute;
  left: 480px;
  top: 450px
}

.member-name {
  font-family: "Podkova", serif;
  font-size: 40px;
  color: white;
}

.member-role {
  font-family: "Podkova", serif;
  font-size: 26px;
  color: white;
}

.member-desc {
  font-family: "Podkova", serif;
  font-size: 16px;
  color: black;
  margin-top: 20px;
}

.contact {
  width: 100%;
  position: relative;
  padding: 80px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-row {
  display: flex;
  flex-direction: row;
  padding: 20px;
  width: 100%;
  justify-content: space-between;
}

.contact-part {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.contact-title {
  /* color: #F25925; */
  color: white;
  font-size: 42px;
  font-family: "Podkova", serif;
}

.contact-desc {
  margin-top: 20px;
  font-size: 22px;
  font-family: "Podkova", serif;
}

.spacer {
  width: 1px;
  background-color: black;
  height: 240px;
  margin-top: 40px;
}

.contact-detail {
  margin-top: 30px;
  font-size: 26px;
  font-weight: 500;
  font-family: "Podkova", serif;
}

.inputrow {
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-top: 50px;
}

.contact-input {
  width: 400px;
  height: 40px;
  outline: none;
  border: none;
  border-bottom: 1px solid black;
  background-color: transparent;
  font-size: 26px;
  font-weight: 500;
  font-family: "Podkova", serif;
}

.contact-input::placeholder {
  font-size: 26px;
  font-weight: 500;
  font-family: "Podkova", serif;
}

.submit {
  background-color: #F25925;
  border-radius: 999px;
  padding: 15px 30px;
  width: fit-content;
  color: white;
  font-weight: 500;
  font-size: 24px;
  font-family: "Podkova", serif;
  margin-top: 40px;
  cursor: pointer;
  outline: none;
  border: none;
}

.submit:hover {
  background-color: #F25925aa;
}

.project-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-top: 40px;
}

.project-row img {
  width: 300px;
  height: 200px;
}

.seeall {
  background-color: #F25925;
  border-radius: 999px;
  padding: 10px 30px;
  font-weight: bold;
  font-size: 22px;
  font-family: "Podkova", serif;
  cursor: pointer;
  margin-top: 30px;
}

.seeall:hover {
  background-color: #F25925aa;
}

.footer {
  background-color: #333333;
  width: 100%;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-row {
  width: 400px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.icon-row img {
  cursor: pointer;
}

.icon-row svg {
  cursor: pointer;
}

.ft {
  font-size: 22px;
  font-family: "Podkova", serif;
  font-weight: 400;
  color: white;
}

.null {
  animation: slideUp 0.5s ease forwards;
  /* visibility: hidden; */
}

.visible {

  animation: slideDown 0.5s ease forwards;
}

.allprojects {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.allprojects img {
  object-fit: cover;
  aspect-ratio: 1;
  width: 30%;
  border-radius: 16px;
}

.apblock {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 80px;
  gap: 40px;
}

.aptitle {
  font-family: "Oswald", sans-serif;
  font-size: 60px;
  z-index: 5;
  background-color: white;
  width: 100%;
  display: flex;
  padding: 20px 0;
  justify-content: center;
}

@media screen and (max-width: 768px) {

  .menu {

    background-color: #000000aa;
  }

  .menu-tab {
    font-size: 16px;
    will-change: opacity;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    opacity: var(--opacity);
  }

  .menu img {
    width: 64px;
    height: 64px;
  }

  .menu-btn {
    margin-left: auto;
    margin-right: 20px;
    cursor: pointer;
  }

  .menu-dropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 64px;
    width: 100%;
    background-color: #000000aa;
  }

  .menu-tab {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    flex: 1;
    display: flex;
    align-items: center;
  }

  .dropstart {
    animation: dropdown 0.5s ease forwards;
  }

  .dropend {
    animation: dropup 0.5s ease forwards;
  }

  .gesture-detector {
    background-color: transparent;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }

  .home-main {
    margin-top: 0px;
  }

  .main-title {
    font-size: 32px;
  }

  .main-desc {
    font-size: 16px;
    margin-top: 5px;
  }

  .learn-btn {
    padding: 10px 20px;
    font-size: 18px;
    margin-top: 50px;
  }

  .about {
    flex-direction: column;
    padding: 20px 20px;

  }

  .about-left {
    width: 100%;
    letter-spacing: 1.5px;
  }

  .about-title {
    font-size: 28px;
  }

  .about-desc {
    font-size: 16px;
  }

  .about-right {
    width: 100%;
  }

  .about-bg-desc {
    right: 80px;
  }

  .services {
    padding: 40px 0px;
  }

  .service-title {
    font-size: 32px;
  }

  .service-item {
    padding: 20px 0px;
    width: 30%;
  }

  .service-name {
    font-size: 24px;
  }

  .service-desc {
    font-size: 14px;
  }

  .services-bg {
    height: calc(100% - 160px);
    top: 160px;
  }

  .flex-3 {
    width: 100%;
    margin-bottom: 20px;
  }

  .fake-btn {
    font-size: 12px;
  }

  .projects {
    margin-top: 0;
    justify-content: space-evenly;
  }

  .project-row img {
    width: 100px;
    height: unset;
  }

  .seeall {
    font-size: 16px;
  }

  .mobileteam {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
  }

  .m-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-top: 15px;
  }

  .m-member img {
    width: 128px;
    height: 128px;
    object-fit: cover;
    border-radius: 999px;
  }

  .member-panel {
    width: 100%;
    position: relative;
    padding: 10px 15px;
    align-items: center;
    top: unset;
    left: unset;
  }

  .member-name {
    font-size: 32px;
  }

  .member-role {
    font-size: 24px;
    text-align: center;
  }

  .member-desc {
    margin-top: 5px;
    text-align: center;
  }

  .contact {
    margin-top: 0;
    padding: 0 20px;
    text-align: center;
  }

  .contact-title {
    font-size: 22px;
    text-align: center;
    margin-top: 20px;
  }

  .contact-desc {
    font-size: 16px;
    text-align: start;
  }

  .contact-detail {
    font-size: 16px !important;
    text-align: start;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .contact-part {
    width: 100%;
    justify-content: center;
  }

  .inputrow {
    flex-direction: column;
    max-width: 100vw;
    margin-top: 20px;
  }

  .contact-input {
    width: 100%;
  }

  .contact-input {
    width: 100%;
    font-size: 16px !important;
  }

  input {
    font-size: 16px !important;
  }

  textarea::placeholder {
    font-size: 16px !important;
  }

  input::placeholder {
    font-size: 16px !important;
  }

  .submit {
    font-size: 16px;
    padding: 10px 20px;
  }

  .footer {
    width: 100vw;
    height: 60px !important;
    padding: 20px 0;
  }

  .icon-row {
    width: 200px;
    margin-top: 5px;
  }

  .footer img {
    width: 16px;
    height: 16px;
  }

  .ft {
    margin-top: 0;
    font-size: 12px;
  }


  .allprojects {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
  }

  .allprojects img {
    object-fit: cover;
    aspect-ratio: 1;
    flex: 1;
    border-radius: 16px;
  }

  .apblock {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 20px;
    gap: 40px;
  }

  .aptitle {
    font-family: "Oswald", sans-serif;
    font-size: 28px;
    z-index: 5;
    background-color: white;
    width: 100%;
    display: flex;
    padding: 5px 0;
    justify-content: center !important;
    text-align: center;
  }

  .apblock {
    gap: 10px;
  }

  .space {
    height: 50px;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideUp {
  to {
    transform: translateY(-100%);
    opacity: 0;
  }

  from {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes dropdown {
  from {
    height: 0;
  }

  to {
    height: 45vh;
  }
}

@keyframes dropup {
  0% {
    --opacity: 1;
    height: 45vh;
  }

  20% {
    --opacity: 0;
    height: 45vh;
  }

  100% {
    height: 0;
    --opacity: 0;
    visibility: collapse;
  }
}